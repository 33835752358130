.select {
  margin-top: 20px;
  width: 300px;
}

.file {
  width: 300px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn__upload {
  width: 100px;
  margin-top: 20px;
}

.api__response {
  margin-top: 20px;
}

input[type="file"] {
  font-size: 13px;
}

.btn-upload-partners {
  height: 55px;
  width: 343px;
  border-radius: 8px;
  align-items: center;
  background-color: #e7b400;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fieldset-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.container-selected-partners-img {
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

ul {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
}

ul li a{
  color: white;
  cursor: pointer;
}

.active {
  color: var(--color-aside-active);
}

.total{
   color: white;
   margin: 10px 0;
}

.footer {
  width: 95%;
}

.container-create-users {
  display: flex;
  width: 100%;
  margin: 25px 0 35px 0;
  justify-content: space-between;
}

.users-filters {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.user-filters-item {
  color: white;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.users-filters-buttons {
    display:flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
@import "./styles/variables.css";

* {
  padding: 0;
  margin: 0;
  font-family: Poppins;
}

body {
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--blanco);
  width: 100%;
  min-width: 1000px;
}

body.fondo {
  /* background: url("./assets/images/fondoLogin.png") no-repeat
    rgba(22, 43, 40, 0.56); */
  background-color: var(--background-color-login);
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 720px) {
  body {
    min-width: unset;
    /* height: 100vw; */
    /* position: fixed; */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #252525;
  border-radius: 10px;
}

#root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background: #181818;
  padding-right: 8px;
}

.custom-select {
  padding: 0.7rem;
  border: 1px solid #ddd;
  background: white;
  outline: none;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 80%;
}

.input-edit-user-data {
  margin-bottom: 20px;
  padding: 0.7rem;
  border: 1px solid #ddd;
  background: white;
  outline: none;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 80%;
}

.form-edit-user {
  width: 25rem;
}

.user-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.user-select select {
  width: 85%;
}

.error-message-suscription {
  color: red;
  margin: 10px 0;
}

.btn-create-club {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  background-color: #e7b400;
  align-self: center;
}

.btn-clean-clubs {
  margin-left: 150px;
  width: 130px;
}

.img-clubs {
  width: 50px;
  height: 50px;
}

.container-create-club {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.container-btn-clubs {
  display: flex;
  flex-direction: row;
  margin-left: 250px;
  position: absolute;
  top: 40px;
}

.input-filter-clubs {
  border-width: 0;
  border-bottom-width: 1px;
  background-color: transparent;
  color: #ffffff;
}

.btn-search-club {
  display: flex;
  background-color: transparent;
  border-width: 0;
  align-items: center;
}

.btn-go-edit {
  display: flex;
  background-color: transparent;
  border-width: 0;
  align-items: center;
  cursor: pointer;
}

.btn-edit-club {
  padding: 4px;
  color: #ffffff;
  background-color: transparent;
  border-radius: 4px;
}

@import "../../../styles/animations.css";
.aside-container {
  animation: fadeIn 0.5s ease;
  flex-basis: 17%;
  max-width: 240px;
  position: relative;
  box-sizing: border-box;
  padding: 30px 18px;
  background-color: #252525;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.aside-container.mobile {
  animation: sliceRight 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 235px;
  z-index: 999;
  overflow-y: scroll;
}
.aside-session-container {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.aside-session-container-userdata {
  display: flex;
  flex-direction: column;
}
.aside-session-container-userdata h3 {
  font-size: 18px;
  font-family: Poppins-Bold;
}
.aside-session-container-userdata .name {
  font-size: 12px;
  line-height: 1;
  font-family: Poppins-Medium;
}
.aside-session-container-img {
  width: 38px;
  height: 38px;
  border-radius: 6px;
  background-color: var(--user-icon-background);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75em;
}
.aside-session-container-img svg {
  max-width: 30px;
  max-height: 30px;
}
.options-container svg,
.foot-aside-container svg {
  margin-right: 0.75em;
  max-width: 20px;
  max-height: 20px;
}
.options-container .liStyle,
.foot-aside-container .liStyle {
  padding: 0;
}
.options-container .liStyle a,
.foot-aside-container .liStyle span {
  padding: 0.75em 0em 0.75em 0.5em;
  display: flex;
  align-items: center;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: var(--color-aside-inactive);
}
.options-container .liStyle:hover,
.foot-aside-container .liStyle:hover {
  background-color: var(--aside-item-active-background);
  border-radius: 3px;
}
.options-container .liStyle:hover a,
.foot-aside-container .liStyle:hover span {
  color: var(--aside-text);
}
.options-container .liStyle a.active {
  color: var(--color-aside-active);
}
.options-container .liStyle a svg,
.foot-aside-container .liStyle span svg {
  stroke: var(--aside-text);
  fill: var(--aside-text);
}
.options-container .liStyle a.active svg {
  stroke: var(--aside-text-active);
  fill: var(--aside-text-active);
}
.foot-aside-container {
  font-size: 1rem;
  margin-top: auto;
  width: 100%;
}

.foot-aside-container label {
  color: var(--cerrar-sesion);
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  padding: 0.75em 0em 0.75em 0.5em;
  display: block;
}
.foot-aside-container .liStyle {
  cursor: pointer;
}

.close-sesion-container div {
  padding: 0.5rem 0;
  text-align: center;
}

.close-sesion-actions span {
  margin-right: 1rem;
  cursor: pointer;
}

.close-sesion-container span:first-child {
  color: var(--color-principal);
}

.close-sesion-container span:first-child:hover {
  color: var(--gris5);
}

.container-update-avatar {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}

.body-update {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.avatar-container {
  display: flex;
  align-items: center;
}

.avatar-container-id {
  color: white;
  margin-top: 10px;
  margin-bottom: auto;
}

.avatar-container-id h3 {
  margin-bottom: 20px;
}

.avatar-container-show {
  display: flex;
  flex-direction: column;
}

.input-update {
  background-color: transparent;
  width: 160px;
}

.update-btn {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  border-width: 0;
  background-color: #e7b400;
  margin-bottom: 20px;
  margin-top: 20px;
  align-self: center;
}

.fieldset-update-avatar {
  border-radius: 4px;
  margin-top: 20px;
}

@import "../../styles/animations.css";

.modal-container {
  top: 0;
  left: 0;
  z-index: 30;
  display: block;
  position: absolute;
  height: 300vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.2, 0.44, 1) forwards;
}
.container-out {
  animation: fadeOut 0.4s 0.2s cubic-bezier(0.165, 0.2, 0.44, 1) forwards;
}
.modal-wrapper {
  position: absolute;
  z-index: 31;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.modal-content {
  background-color: var(--blanco);
  border-radius: 7px;
  padding: 0;
  z-index: 40;
  min-width: 25vw;
  transform: scale(0);
  animation: scaleIn 0.3s 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.out {
  animation: scaleOut 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-close {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  background-color: transparent;
}

.modal-close .close {
  max-width: 15px;
  max-height: 15px;
  cursor: pointer;
}

.container-children {
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
}

.tooltips-simple {
  border-radius: 5px;
  position: absolute;
  z-index: 20;
  background-color: #fff;
  min-width: 15vw;
  padding: 1rem;
  box-shadow: 0px 0px 3px #ddd;
}

.tooltips-alert {
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -150%);
  border-radius: 5px;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(132, 81, 161, 1) 0%,
    rgba(132, 81, 161, 0.83) 90%
  );
  color: #fff;
  height: 2rem;
  width: 9rem;
  padding: 0.5rem;
}

.tooltips-alert::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-right: 5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(132, 81, 161, 1) transparent transparent transparent;
}

.top {
  animation: tooltips 0.4s ease forwards;
}
.closeTop {
  animation: tooltipsClose 0.4s ease forwards;
}

@keyframes tooltipsClose {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes tooltips {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

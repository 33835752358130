.container-avatar {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-top: 20px;
}

.container-files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.delete-avatar {
  background-color: transparent;
  position: absolute;
  width: 20px;
  margin-left: 10px;
  margin-top: 10px;
}

.button-upload {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  background-color: #e7b400;
  margin-bottom: 20px;
}

.input-add-avatar {
  margin-bottom: 20px;
  color: #ffffff;
}

.fieldset-avatar {
  border-radius: 4px;
}

.btn-modify-avatar {
  padding: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.container-create-avatars {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin: 25px 0 35px 0;
}

.image {
  width: auto;
  height: 200px;
}

.select-box--box {
  position: relative;
  box-sizing: border-box;
}
.select-box--container {
  height: 33px;
  width: 100%;
  margin: 0;
  position: relative;
}
.select-box--selected-item {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-transform: inherit;
  padding: 0 0.75rem;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px var(--gris3);
  font-size: 14px;
  line-height: 2;
}
.select-box--selected-item.before {
  padding: 0px 1.5rem;
}
.select-box--selected-item::first-letter {
  text-transform: uppercase;
}

.select-box--items {
  margin-top: -2px;
  background-color: var(--blanco);
  width: calc(100% + 10px);
}

.select-box--items div {
  border: 1px solid var(--gris);
  border-top: none;
  padding: 4px 6px;
  position: relative;
  text-transform: capitalize;
  font-size: 14px;
}
.select-box--items div.before {
  padding: 4px 1.5rem;
}
.select-box--items div:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select-box--items div:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.select-box--items div:hover {
  cursor: pointer;
  background-color: var(--gris4);
}

.select-box--items div.selected::after {
  content: "";
  position: absolute;
  right: 9px;
  top: 30%;
  width: 4px;
  height: 8px;
  border: solid var(--select-selected);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select-box--arrow {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.select-box--arrow-down {
  position: absolute;
  top: 40%;
  right: 15px;
  width: 6px;
  height: 6px;
  border: solid var(--arrow);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
  transition: 0.2s;
}

.select-box--arrow-up {
  position: absolute;
  top: 35%;
  right: 10px;
  width: 6px;
  height: 6px;
  border: solid var(--arrow);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(-135deg) translateY(-50%);
  -ms-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
  transition: 0.2s;
}

.status {
  position: absolute;
  top: 35%;
  left: 6%;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.status--pendiente {
  background: rgb(255, 88, 127);
}
.status--revision {
  background: rgb(255, 223, 88);
}
.status--procesada {
  background: rgb(109, 244, 145);
}

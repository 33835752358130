.container-crate-decisión {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-top: 15px;
  align-items: left;
}

.input-title {
  display: flex;
  width: 430px;
  height: 60px;
  border-radius: 8px;
  background-color: transparent;
  border-width: 0px;
  outline: none;
  color: #ffffff;
}

.p-invalid {
  display: flex;
  width: 430px;
  height: 20px;
  border-radius: 8px;
  background-color: transparent;
  border-width: 0px;
  outline: none;
  color: #f00b0b;
}

.transformY-20 {
  transform:translateY(-20px);
}

.div-title {
  margin-bottom: 20px;
}

.p-invalid {
  display: flex;
  width: 430px;
  height: 20px;
  border-radius: 8px;
  background-color: transparent;
  border-width: 0px;
  outline: none;
  color: #f00b0b;
}

.text-desc {
  width: 95%;
  height: 90%;
  background-color: transparent;
  border-width: 0px;
  outline: none;
  color: #ffffff;
}

.date-picker {
  margin-bottom: 10px;
}

.input-image-decision {
  margin-bottom: 20px;
  color: #ffffff;
}

.create-decision-btn {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  background-color: #e7b400;
  align-self: left;
  margin-bottom: 20px;
}

.h4-text-decision {
  color: #626262;
}

.container {
  transform: translateY(-100%);
}

.container p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 449px;
  height: 202px;
  border: 1px dashed #626262;
  border-radius: 8px;
  background-color: #181818;
}

.file-input {
  display: none;
}

.img-input {
  margin-top: 20px;
}

.container-date-picker {
  margin-top: 20px;
}

.fieldset-crete-decision {
  width: 80%;
  border-width: 1px;
  border-radius: 4px;
}

.select-league-decision {
  width: 300px;
  background-color: #303030;
  border-radius: 4px;
  color: #ffffff;
}

.container-input-decision {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 60px;
  border-radius: 8px;
  background-color: #303030;
}

.container-text-area-decision {
  width: 450px;
  height: 202px;
  background-color: #303030;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.container-selected-decision-img {
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/Poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

body {
  margin: 0;
  font-size: 16px;
}
input,
select {
  font-size: 100%;
}

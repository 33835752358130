a:hover,
a:active,
.active {
  text-decoration: none;
  color: var(--color-principal);
}

a {
  color: rgb(66, 82, 110);
  text-decoration: none;
  font-family: Poppins-Medium;
}

.linkOne {
  padding-bottom: 16px;
}
.liStyle {
  list-style-type: none;
  font-size: 1rem;
  padding-bottom: 8px;
  line-height: 24px;
}
.liStyle.one {
  padding-bottom: 16px;
}

.container-avatar {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-top: 20px;
}

.container-files {
  display: flex;
  flex-direction: column;
}

.delete-avatar {
  background-color: transparent;
  position: absolute;
  width: 20px;
  margin-left: 10px;
  margin-top: 10px;
}

.button-upload-option {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  background-color: #e7b400;
  margin-bottom: 20px;
}

.input-add-avatar {
  margin-bottom: 20px;
  color: #ffffff;
}

.fieldset-avatar {
  border-radius: 4px;
}

.btn-modify-avatar {
  padding: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.container-option-btn {
  width: 100%;
  display: "flex";
  justify-content: center;
  align-items: center;
}

.options-card {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-save {
  margin-left: 40px;
  border-radius: 4px;
  height: 30px;
  width: 130px;
}

.btn-div-save {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.container-edit {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  align-items: center;
}

.name-input {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.logo-contain {
  margin-bottom: 30px;
  width: 100%;
}

.image {
  width: 200px;
  height: 200px;
}

.p-hex {
  margin-bottom: 10px;
  color: #ffffff;
}

.btn-save-club {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  background-color: #e7b400;
}

.container-edit-club {
  display: flex;
  flex-direction: row;
}

.form-control-edit-club {
  background-color: transparent;
  color: #ffffff;
  border-radius: 4px;
  width: 250px;
}

.form-control {
  color: #ffffff;
}

.lbl-input-check {
  margin-top: 20px;
  color: #ffffff;
  width: fit-content;
}

.user-info-item {
  margin-bottom: 30px;

  color: white;
  width: fit-content;
}

.custom-select {
  padding: 0.7rem;
  border: 1px solid #ddd;
  background: white;
  outline: none;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 80%;
}

.input-edit-user-data {
  margin-bottom: 20px;
  width: 100%;
}

.form-edit-user {
  width: 40rem;
}

.div-edit-user-select {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.error-message-suscription {
  color: red;
}

.text-desc-edit-option {
  height: 100px;
  width: 100%;
  min-width: 300px;
  min-height: 100px;
}

.option-img-edit {
  height: 200px;
  width: 200px;
  margin-top: 10px;
}

.avatar-container-edit-option {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.input-count-option {
  width: 100px;
  margin-bottom: 10px;
}

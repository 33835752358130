.content-leagues {
  align-items: center;
}

.btn-create-league {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  background-color: #e7b400;
  margin-bottom: 20px;
  margin-top: 20px;
  align-self: center;
}

.btn-leagues-search {
  position: absolute;
  top: 35px;
  margin-left: 165px;
  display: flex;
  justify-content: "space-between";
}

.btn-clean-filters {
  margin-left: 150px;
  width: 130px;
}

.td-leagues {
  width: 500px;
}

.img-leagues {
  width: 40px;
  height: 40px;
}

.button-create {
  background-color: transparent;
  color: #ffffff;
  padding: 3px;
  border-radius: 4px;
}

.search-league {
  color: #ffffff;
  background-color: transparent;
  border-width: 0;
  border-bottom-width: 1px;
}

.btn-search-league {
  background-color: transparent;
  display: flex;
  align-items: center;
  border-width: 0;
  margin-left: 10px;
}

.content-leagues-create {
  display: flex;
  justify-content: center;
}

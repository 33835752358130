.input-login {
  margin-bottom: 1rem;
  padding: 0.7rem;
  border-radius: 8px;
  border: 0;
  outline: none;
  background-color: #303030;
  color: #fff;
}
.input:focus {
  box-shadow: 0px 0px 4px #ddd;
}

.input:disabled {
  box-shadow: 0px 0px 4px #ddd;
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.input-form {
  margin-bottom: 20px;
  padding: 0.7rem;
  border: 1px solid #ddd;
  background: white;
  outline: none;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 80%;
  color: #000;
}
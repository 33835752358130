@import "../../styles/animations.css";

.login-container {
  height: 100vh;
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease forwards;
  color: var(--negro);
}
@media screen and (max-width: 720px) {
  .login-container {
    width: 90%;
  }
}

.header-container,
.subheader-container,
.form-container,
.foot-login-container {
  box-sizing: border-box;
  padding: 0.5rem;
  width: 100%;
  max-width: 390px;
  text-align: start;
}
.header-container h1 {
  background: linear-gradient(to right, #00a795 2%, #0dc259 100%);
  font-size: 28px;
  letter-spacing: 8px;
  font-family: "Poppins-bold", sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subheader-container {
  font-size: 19px;
}
.subheader-container p {
  font-size: 16px;
}

.form-container {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.form-container input {
  padding: 15px 23px;
  font-size: 14px;
}
.form-container input::placeholder {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 1.56;
}
.form-container button {
  border: none;
  font-weight: 500;
  padding: 0.8rem;
  border-radius: 5px;
  color: var(--blanco);
  background-color: #f0c31b;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.29);
}

.foot-login-container small {
  font-size: 12px;
  /* color: rgb(129, 129, 129); */
}
.foot-login-container small span {
  cursor: pointer;
  text-decoration: underline;
  /* color: var(--color-principal); */
}

.form-container .input-error {
  color: var(--rojo);
  font-size: 12px;
  margin-top: -0.75rem;
  margin-bottom: 0.5rem;
}

.container-form-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-background {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/* color variables */
body {
  --titulo-principal: var(--negro);
  --aside-background: var(--blanco3);
  --aside-text: var(--azul);
  --aside-text-active: var(--color-principal);
  --aside-item-active-background: var(--gris4);
  --cerrar-sesion: var(--rosa);
  --main-background: var(--blanco);
  --button-principal: var(--color-principal);
  --table-header-text: var(--negro);
  --input-border: var(--gris);
  --arrow: var(--gris5);
  --select-selected: var(--gris5);
  --user-icon-background: var(--gris8);
  --background-color-login: var(--loginBackground);

  --azul: rgb(66, 82, 110);
  --azul1: #717386;
  --blanco: #fff;
  --blanco2: #f5f5f5;
  --gris: #ddd;
  --gris2: #bebebe;
  --gris5: rgb(113, 115, 134);
  --negro: #000;
  --color-principal: #57838d;
  --blanco3: rgb(244, 245, 247);
  --success: #2ecc71;
  --error: #fc5a5a;
  --rojo: rgb(255, 0, 0);
  --rosa: rgb(245, 19, 141);
  --gris4: rgb(235, 236, 240);
  --gris8: #b7b4b7;
  --pendiente: rgb(255, 88, 127);
  --revision: rgb(255, 223, 88);
  --procesada: rgb(109, 244, 145);
  --loginBackground: #e0f8f5;
  --color-aside-active: #fed543;
  --color-aside-inactive: #ffffff;
}

.col-md-3 {
  margin-bottom: 30px;
}

.contain-league {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contain-image {
  margin-bottom: 30px;
  width: 400px;
}

.img {
  width: 200px;
  height: 200px;
}

.contain-create {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.select {
  margin-bottom: 20px;
}

.input {
  width: 100%;
}

.p-hex {
  margin-bottom: 10px;
}

.select-league {
  width: 300;
  background-color: #303030;
  border-radius: 4;
  color: #ffffff;
  margin-bottom: 20px;
  border-radius: 4px;
}

.form-control-create-club {
  background-color: #303030;
  border-radius: 4px;
  color: #ffffff;
}

.title {
  color: #ffffff;
}

.hex {
  margin-bottom: 20px;
}

.fieldset-create-club {
  width: 80%;
}

.input-clubs {
  color: #ffffff;
}

.footer {
  display: flex;
  margin-top: 40px;
}

.btn-create-club {
  width: 343px;
  height: 55px;
}

.goal {
  margin-top: 20px;
}

.container-banner {
  margin-bottom: 20px;
}

.banner {
  margin-bottom: 20px;
}

.title-banner {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
}

.image-container {
  margin-bottom: 20px;
}

.title-images {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
}

.image {
  width: auto;
  height: 200px;
}

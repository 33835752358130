.btn-create-decision {
  height: 55px;
  width: 343px;
  border-radius: 8px;
  align-items: center;
  background-color: #e7b400;
}

.btn-decision-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 250px;
  position: absolute;
  top: 17px;
}

.btn-clean-decision {
  margin-left: 150px;
  width: 130px;
}

.form-control-decision {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom: 1;
  background-color: transparent;
  color: #ffffff;
}

.container-create-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;
}

.container-search-input-btn {
  display: flex;
  flex-direction: row;
}

.btn-search-club {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-width: 0;
}

.container-decision-league {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
}

.btn-search-league-decision {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-width: 0;
}

.filter-input-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@import "../../styles/animations.css";

.button {
  background-color: #f0c31b;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 0.2rem;
  color: #fff;
  border: 1px solid var(--button-principal);
  outline: none;
  font-weight: 500;
  box-sizing: border-box;
  font-size: 1rem;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  border: none;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.button i {
  font-size: 0.8rem;
  animation: sliceUp 0.4s ease forwards;
}

.button.disabled,
.button.success,
.button.error {
  opacity: 0.8;
}
.button.success {
  opacity: 1;
  background-color: var(--success);
}
.button.error {
  opacity: 1;
  background-color: red;
  border-color: red;
}

.big-button {
  height: 55px;
  width: 343px;
  border-radius: 8px;
  background-color: #e7b400;
  color:#000;
  font-size: 0.85rem;
}

.button-secondary {
  background-color: #e7b400;
  color:#000;
  font-size: 0.85rem;
}

.delete-button {
  background-color: red;
  color:#fff;
}
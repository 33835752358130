.contact-form-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem 1rem;
}
.contact-form-container h3 {
  padding: 0.5rem 0;
  margin: 0;
  font-size: 28px;
  color: #000;
  font-weight: 600;
  text-align: left;
}
.contact-form-container textarea {
  border-color: rgb(211, 211, 211);
  padding: 0.75rem;
  font-size: 14px;
  border-radius: 5px;
}

.contact-form-container button {
  width: 10vw;
  margin-left: auto;
  font-weight: 500;
  margin-top: 1rem;
}
@media screen and (max-width: 720px) {
  .contact-form-container button {
    width: 100%;
  }
  .contact-form-container textarea {
    max-width: 70vw;
  }
  .contact-form-container h3 {
    font-size: 20px;
  }
}

@import "../../../styles/animations.css";
.main-container {
  animation: fadeIn 0.5s ease;
  box-sizing: border-box;
  flex: 1 83%;
  padding: 1.5rem;
  padding-left: 3.8rem;
  height: 95vh;
  width: 95%;
  overflow-y: scroll;
  position: relative;
  background-color: #181818;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  border-color: var(--main-background);
  border-radius: 10px;
}

.title {
  box-sizing: border-box;
  margin-bottom: 0rem;
  width: 95%;
}

.title h2 {
  font-family: Poppins-SemiBold;
  font-size: 35px;
  color: #ffffff;
}

@media screen and (max-width: 720px) {
  .main-container {
    padding-left: 1.5rem;
    padding-top: 2.5rem;
  }
  .title h2 {
    font-size: 20px;
  }
  .title.reporte {
    margin-left: 2rem;
  }
  .title.reporte ~ .openMenu {
    opacity: 0;
  }
}

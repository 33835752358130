.wrapper {
    padding: 0 16px 16px;
}

.confirm-modal .button-actions {
    margin-top: 15px;
}

.confirm-modal .modal-state {
    font-size: 14px;
}

.confirm-modal .modal-state.error {
    color: var(--error);
}

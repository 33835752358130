.btn-create {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  background-color: #e7b400;
  align-self: center;
}

.btn-clean {
  margin-left: 150px;
  width: 130px;
}

.img {
  width: 50px;
  height: 50px;
}

.container-create {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.container-btn {
  display: flex;
  flex-direction: row;
  margin-left: 250px;
  position: absolute;
  top: 40px;
}

.input-filter {
  border-width: 0;
  border-bottom-width: 1px;
  background-color: transparent;
  color: #ffffff;
}

.btn-search {
  display: flex;
  background-color: transparent;
  border-width: 0;
  align-items: center;
}

.btn-go-edit {
  display: flex;
  background-color: transparent;
  border-width: 0;
  align-items: center;
}

.btn-edit {
  padding: 4px;
  color: #ffffff;
  background-color: transparent;
  border-radius: 4px;
}

.user-filter-input {
  padding: 0.7rem;
  outline: none;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.user-filter-date {
  padding: 0.7rem;
  border-radius: 5px;
  margin-bottom: 5px;
}

.buttons-filter {
  display: flex;
  justify-content: space-evenly;
}

.container-user-filter {
  display: flex;
  flex-direction: row;
}

.button {
  background-color: #e7b400;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 0.2rem;
  color: #000;
  border: 1px solid var(--button-principal);
  outline: none;
  font-weight: 500;
  box-sizing: border-box;
  font-size: 0.85rem;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  border: none;
}

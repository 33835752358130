.table {
  border-collapse: collapse;
  border: none;
  width: 95%;
  font-weight: 500;
}

.table thead tr td,
.table tfoot tr td {
  background-color: #181818;
  padding: 0.5rem 0.25rem;
  color: var(--color-principal);
  border: none;
  text-align: start;
}

.table thead tr:last-child td {
  color: #ffffff;
  /* border-bottom: 1px solid #e3e3e3; */
  font-size: 14px;
}

.table thead tr:first-child td:first-child {
  border-top-left-radius: 8px;
}
.table thead tr:first-child td:last-child {
  border-top-right-radius: 8px;
}

.table tbody tr td {
  border-top: 1px solid #e3e3e3;
  background-color: #181818;
  font-family: "Heebo", sans-serif;
  color: #ffffff;
  font-size: 14px;
  padding: 0.5rem 0.25rem;
  text-align: start;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table tbody tr:last-child td {
  border-bottom: 1px solid #e3e3e3;
}

.headers-option-multi {
  display: grid;
  grid-template-columns: 100%;
  align-items: stretch;
}
.headers-option-single {
  display: flex;
  align-items: flex-end;
}
.headers-option {
  flex-wrap: wrap;
  width: 95%;
  padding: 0.5rem 0;
  margin: 0.5rem 0 1rem;
}
.headers-option-abm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  width: 95%;
  margin: 2rem 0 1rem;
}
.headers-option .date {
  width: auto;
  margin: 0;
  color: var(--azul);
}

.filter-selected {
  width: 100%;
  color: var(--gris5);
  margin-top: 2rem;
}
.filter-selected .select-box--items {
  border-top: 1px solid var(--gris);
  box-shadow: 0px 0px 10px var(--gris);
  border-radius: 7px;
  margin-top: 0;
}
.filter-selected .select-box--items div {
  padding: 6px;
}
.filter-selected .select-box--items div:first-child {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.filter-selected .select-box--items div:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.filter-selected .select-box--selected-item {
  border: none;
  background-color: transparent;
  padding-left: 0;
  outline: none;
  font-size: 16px;
}

.add {
  position: relative;
  text-align: end;
}

.add button {
  background-color: var(--color-principal);
  width: 145px;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  color: var(--blanco);
  border-radius: 5px;
  cursor: pointer;
}

.footer-option small,
.footer-option label {
  padding: 0.5rem;
  cursor: pointer;
}

.footer-option label {
  color: var(--negro);
}

.footer-option {
  background-color: #181818;
  color: #99a3a4;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row-items {
  cursor: pointer;
}
.row-items:hover td {
  background-color: #f8f9fa;
}

.button-destacado {
  justify-content: center;
}

.button-actions {
  display: flex;
  /* justify-content: center; */
}

.button-actions .button-action {
  cursor: pointer;
  padding: 0.35rem 1.25rem;
  border-radius: 6px;
  background-image: linear-gradient(to right, #00a697, #0fd55b);
  color: var(--blanco);
}

.red {
  color: var(--rojo);
}

.green {
  color: var(--verde1);
}
@media screen and (max-width: 720px) {
  .table {
    width: 100%;
  }
  .table thead tr,
  .table tbody tr {
    display: flex;
  }
  .table thead tr td,
  .table tbody tr td {
    display: none;
  }
  .table thead td:nth-child(1),
  .table thead td:nth-child(5),
  .table thead td:nth-child(6),
  .table tbody td:nth-child(1),
  .table tbody td:nth-child(5),
  .table tbody td:nth-child(6) {
    display: block;
    width: 33%;
    padding: 0.75rem 0.25rem;
  }
  .button-actions .button-action {
    padding: 0.25rem 0.75rem;
    font-size: 12px;
  }
  .table tbody tr .button-actions {
    text-align: right;
    padding-right: 0;
  }
  .filter-selected {
    margin-top: 1rem;
  }
  .filter-selected .select-box--selected-item {
    font-size: 14px;
  }
  .filter-selected .select-box--arrow-down {
    right: 30px;
  }
  .filter-selected .select-box--arrow-up {
    right: 25px;
  }
}

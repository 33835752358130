.fieldset-create-league {
  width: 70%;
  border-radius: 4px;
}

.create-league-btn {
  width: 343px;
  height: 55px;
  border-radius: 8px;
  background-color: #e7b400;
  align-self: center;
  margin-bottom: 20px;
  margin-top: 20px;
  align-self: center;
}

.container-input-create-league {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 450px;
  height: 60px;
  border-radius: 8px;
  background-color: #303030;
}

.img-selected-create-league {
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.image {
  width: auto;
  height: 200px;
}
